export default {
  slug: "air-testing-services",
  url: "/products/air-testing-services",
  title: "Air Testing Services",
  content: {
    section1: {
      h1: {
        head: "",
        tail: "Air Testing Services.",
      },
      pIntro: `Clean Air Filter’s testing team has the equipment and experience to travel globally to the customer site for cab integrity testing to aide operator protection. Specific Cab Protection Levels are a balance between “cab integrity” and “filter efficiency”`,
      a:
        '<a class="download-link" href="https://www.cdc.gov/niosh/mining/UserFiles/works/pdfs/2012-180.pdf" target="_blank" rel="noopener noreferrer">Quantifying Unfiltered Air Leakage into Enclosed Cabs</a>',
      a1: `Cab Protection Factor Template`,
      p1: `At CAF, our testing and consulting services include:`,
      ul: [
        `Laboratory and field testing`,
        `Mobile Field Testing on customer site`,
        `Digitally transmit data to CAF for efficiency test report`,
        '<a class="anchor-link" href="https://www.cdc.gov/niosh/mining/UserFiles/works/pdfs/2012-180.pdf" target="_blank" rel="noopener noreferrer">CO<sub>2</sub> Cab Integrity Test</a>',
        `Correlation between particulate and vapor test data`,
        `Service and products to a broad scope of industries with a global customer base`,
        `Mining, agriculture, metal foundries, ethanol plants, landfills, and residential applications`,
        `Specific filtration solutions for hazardous environments`,
        `Air filtration solutions based on patented technology`,
      ],
      p2:
        "Clean Air Filter’s testing team has the equipment and experience to travel globally to the customer site for cab integrity testing to aide operator protection.",
      p3: `Specific Cab Protection Levels are a balance between “cab integrity” and “filter efficiency.”`,
      p4: `Clean Air Filter has been involved with several Original Equipment Manufacturers (OEM) in the development of filtration for new model cabs.`,
    },
    section2: {
      h2: `Vapor Testing Services`,
      p: `CAF can also test cab leakage with vapors. WE test with parts per million (ppm_ instead of particle counts). As expected, the results are more critical, example 98.9% efficiency with particle count equals 98.3% efficiency with vapor.`,
    },
  },
}
